import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { $path } from 'remix-routes';

import { EnumsGamePackVersion } from '@lp-lib/api-service-client/public';

import { PublicLibraryGamePackList } from '../../app/components/PublicLibrary/PublicLibraryLayout';
import { TrainingCard } from '../components/Training/TrainingCard';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const packs = await tokenWithRedirect(
    async () => await apiService.gamePack.getMyGamePacks().next(),
    action.request.url
  );

  return {
    packs,
  };
}

export function Component() {
  const { packs } = useLoaderData<typeof clientLoader>();

  const navigate = useNavigate();

  const handleCreateNewTraining = async () => {
    const res = await apiService.gamePack.create({
      version: EnumsGamePackVersion.GamePackVersionV1,
      childrenIds: [],
      name: 'Untitled Training',
    });

    navigate($path('/trainings/:id/edit', { id: res.data.gamePack.id }));
  };

  return (
    <div className='w-full h-full px-20'>
      <div className='w-full h-15'></div>
      <div className='flex justify-between'>
        <div className='text-2xl font-bold text-white'>My Trainings</div>
        <button
          type='button'
          onClick={handleCreateNewTraining}
          className='btn-delete w-60 h-15'
        >
          Create New Training
        </button>
      </div>

      <PublicLibraryGamePackList
        packs={packs}
        renderCard={(pack) => <TrainingCard pack={pack} />}
      />
    </div>
  );
}
