import { useNavigate } from '@remix-run/react';
import { $path } from 'remix-routes';

import { GamePackCard } from '../../../app/components/GamePack/GamePackCollection';
import { type GamePack } from '../../types/game';

export function TrainingCard(props: { pack: GamePack }) {
  const { pack } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate($path('/trainings/:id/edit', { id: pack.id }));
  };

  return (
    <GamePackCard
      gamePack={pack}
      onClick={handleClick}
      styles={{
        size: 'w-full',
      }}
    />
  );
}
